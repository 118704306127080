export default [

    {
        path: '/login',
        name: 'login',
  
        component: () => import('@/views/auth/Login.vue'),
        meta: {
          layout: 'full',
          requiresUnAuth: true
        },
      },
      {
        path: '/register',
        name: 'register',
        component: () => import('@/views/auth/Register.vue'),
        meta: {
          layout: 'full',
          requiresUnAuth: true,
        },
      },



      {
        path: '/refer/:user',
        name: 'refer',
        // redirect: '/register',
        props: true,
        component: () => import('@/views/auth/Register.vue'),
        meta: {
          layout: 'full',
          requiresUnAuth: true,
        },
      },

      {
        path: '/verify-email',
        name: 'verify-email',
        component: () => import('@/views/auth/VerifyEmail.vue'),
        meta: {
          layout: 'full',
          requiresEmail: true,
          requiresAuth: true,
          
        },
      },

      {
        path: '/forget-password',
        name: 'forget-password',
        component: () => import('@/views/auth/ForgetPassword.vue'),
        meta: {
          layout: 'full',
          requiresUnAuth: true
        },
      },






      {
        path: '/reset-password/:email/:token',
        name: 'reset-password',
        props: true,
        component: () => import('@/views/auth/ResetPassword.vue'),
        meta: {
          layout: 'full',
          requiresUnAuth: true
        },
      },



]
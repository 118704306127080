const mainurl = process.env.NODE_ENV === 'production' ? 'https://dev.lordtech.com': 'http://vtulordtech.test'
const mainApiUrl = mainurl+'/api';

export default {
  // Endpoints
  loginEndpoint:    mainApiUrl+'/login',
  registerEndpoint:  mainApiUrl+'/register',
  resetpasswordEndpoint: mainApiUrl+'/reset/password',
  processresetpasswordEndpoint: mainApiUrl+'/process/reset/password',
  refreshEndpoint:  mainApiUrl+'/me',
  showmaxplangetEndpoint:  mainApiUrl+'/showmax/plan',
  getmonnifylink:  mainApiUrl+'/generate/monnify/transaction',
  showmaxgetEndpoint:  mainApiUrl+'/showmax',
  educationEndpoint:  mainApiUrl+'/education',
  verifypaymentEndpoint:  mainApiUrl+'/verify/payment',
  educationverifyEndpoint:  mainApiUrl+'/education/price',
  resendeducationEndpoint:  mainApiUrl+'/resend/education/pin',
  convertearningEndpoint:  mainApiUrl+'/user/convert/earning',
  processeducationEndpoint: mainApiUrl+'/process/education',
  jambconfirmEndpoint:  mainApiUrl+'/education/confirm/jamb',
  processshowmaxEndpoint: mainApiUrl+'/process/showmax',
  getreferralearningEndpoint:  mainApiUrl+'/get/refearn/history',
  getreferralEndpoint:  mainApiUrl+'/user/get/referrals',
  logoutEndpoint:  mainApiUrl+'/logout',
  verifyemailEndpoint:  mainApiUrl+'/verify/email',
  electricityEndpoint:  mainApiUrl+'/get/electricity',
  airtimeEndpoint:  mainApiUrl+'/get/airtime',
  cabletvEndpoint:  mainApiUrl+'/get/cabletvs',
  processcabletvEndpoint: mainApiUrl+'/process/cabletv',
  processelectricityEndpoint: mainApiUrl+'/process/electricity',
  validatecabletvEndpoint:  mainApiUrl+'/verify/cabletv',
  validateelectricityEndpoint:  mainApiUrl+'/verify/electricity',
  transactionEndpoint:  mainApiUrl+'/get/transaction',
  changepasswordEndpoint:  mainApiUrl+'/change/password',
  addbvnEndpoint:  mainApiUrl+'/user/generate/account',
  getbankEndpoint:  mainApiUrl+'/user/get/account',
  changepinEndpoint:  mainApiUrl+'/change/pin',
  transactionsEndpoint:  mainApiUrl+'/get/transactions',
  depositsEndpoint:  mainApiUrl+'/get/deposits',
  transactionpayEndpoint:  mainApiUrl+'/pay/transaction',
  dataEndpoint:  mainApiUrl+'/get/data',
  mainurl: mainurl + '/',
  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',




//New












}

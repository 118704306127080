import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import VueMonnify from 'vue-monnify'
import VueLoading from 'vue-loading-overlay';
import VuePaystack from 'vue-paystack'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import 'vue-loading-overlay/dist/vue-loading.css';
import '@/libs/sweet-alerts'
import '@/libs/clipboard'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)
Vue.use('VueMonnify', VueMonnify)
Vue.use('VuePaystack', VuePaystack)
Vue.use(VueLoading)

Vue.use(require('vue-moment'));

// import core styles
require('@core/scss/core.scss')


// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = true
Vue.config.devtools = false
// store.dispatch('checkvavailability')
store.dispatch('settuserDetails')

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

export default [
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/views/Home.vue'),
        meta: {
        
          requiresAuth: true,
         
        },
      },


      {
        path: '/wallet/transactions',
        name: 'wallettrans',
        component: () => import('@/views/pages/WalletTransactions.vue'),
        meta: {
          pageTitle: 'Wallet Transactions',
          requiresAuth: true,
         
        },
      },

      {
        path: '/deposit/bank',
        name: 'depositbank',
        component: () => import('@/views/pages/Deposit.vue'),
        meta: {
          pageTitle: 'Add Funds Bank',
          requiresAuth: true,
          breadcrumb: [
            {
              text: 'Add Funds Bank',
      
            },
          ],
        },
      },


      {
        path: '/deposit/method',
        name: 'depositmethod',
        component: () => import('@/views/pages/DepositMethod.vue'),
        meta: {
          pageTitle: 'Add Funds',
          requiresAuth: true,
          breadcrumb: [
            {
              text: 'Add Funds',
      
            },
          ],
        },
      },


      {
        path: '/buy-airtime',
        name: 'buy-airtime',
        component: () => import('@/views/pages/Airtime.vue'),
        meta: {
          pageTitle: 'Buy Airtime',
          requiresAuth: true,
          breadcrumb: [
            {
              text: 'Buy Airtime',
              active: true,
            },
          ],
        },
      },



      {
        path: '/support',
        name: 'support',
        component: () => import('@/views/pages/Support.vue'),
        meta: {
          pageTitle: 'Support',
          requiresAuth: true,
          breadcrumb: [
            {
              text: 'Support',
              active: true,
            },
          ],
        },
      },


      {
        path: '/buy-data',
        name: 'buy-data',
        component: () => import('@/views/pages/Data.vue'),
        meta: {
          pageTitle: 'Buy Data',
          requiresAuth: true,
          breadcrumb: [
            {
              text: 'Buy Data',
              active: true,
            },
          ],
        },
      },
      {
        path: '/cable-tv',
        name: 'cable-tv',
        component: () => import('@/views/pages/Cabletv.vue'),
        meta: {
          pageTitle: 'Subscribe CableTV',
          requiresAuth: true,
          breadcrumb: [
            {
              text: 'Subscribe CableTV',
              active: true,
            },
          ],
        },
      },
      {
        path: '/referrals',
        name: 'my-referrals',
        component: () => import('@/views/pages/Referrals.vue'),
        meta: {
          pageTitle: 'My Referrals',
          requiresAuth: true,
          breadcrumb: [
            {
              text: 'My Referrals',
              active: true,
            },
          ],
        },
      },
      {
        path: '/referral-earn',
        name: 'my-referral-earn',
        component: () => import('@/views/pages/RefEarning.vue'),
        meta: {
          pageTitle: 'My Referral Earnings',
          requiresAuth: true,
          breadcrumb: [
            {
              text: 'My Referrals Earnings',
              active: true,
            },
          ],
        },
      },

      {
        path: '/user/transaction/:hash',
        name: 'transaction',
        component: () => import('@/views/pages/Transaction.vue'),
        props: true,
        meta: {
          pageTitle: 'Transaction',
          requiresAuth: true,
          breadcrumb: [
            {
              text: 'Single Transaction',
              active: true,
            },
          ],
        },
      },

      {
        path: '/user/electricity',
        name: 'electricity',
        component: () => import('@/views/pages/Electricity.vue'),
        meta: {
          pageTitle: 'Electricity',
          requiresAuth: true,
          breadcrumb: [
            {
              text: 'Electricity',
              active: true,
            },
          ],
        },
      },

      {
        path: '/user/showmax',
        name: 'showmax',
        component: () => import('@/views/pages/Showmax.vue'),
        meta: {
          pageTitle: 'Showmax Voacher',
          requiresAuth: true,
          breadcrumb: [
            {
              text: 'Showmax Voacher',
              active: true,
            },
          ],
        },
      },


      {
        path: '/user/education',
        name: 'education',
        component: () => import('@/views/pages/Education.vue'),
        meta: {
          pageTitle: 'Education',
          requiresAuth: true,
          breadcrumb: [
            {
              text: 'Education',
              active: true,
            },
          ],
        },
      },


    

      {
        path: '/user/profile',
        name: 'profile',
        component: () => import('@/views/pages/Profile.vue'),
        meta: {
          pageTitle: 'User Dashboard',
          requiresAuth: true,
          breadcrumb: [
            {
              text: 'User Dashboard',
              active: true,
            },
          ],
        },
      },

      {
        path: '/user/transactions',
        name: 'history',
        component: () => import('@/views/pages/Transactions.vue'),
        meta: {
          pageTitle: 'My Transactions',
          requiresAuth: true,
          breadcrumb: [
            {
              text: 'My Transactions',
              active: true,
            },
          ],
        },
      },
      {
        path: '/user/support',
        name: 'support',
        component: () => import('@/views/Home.vue'),
        meta: {
          pageTitle: 'User Dashboard',
          requiresAuth: true,
          breadcrumb: [
            {
              text: 'User Dashboard',
              active: true,
            },
          ],
        },
      },

      {
        path: '/user/security',
        name: 'security',
        component: () => import('@/views/pages/Security.vue'),
        meta: {
          pageTitle: 'Security',
          requiresAuth: true,
          breadcrumb: [
            {
              text: 'Security',
              active: true,
            },
          ],
        },
      },
     
]
import Vue from 'vue'
import VueRouter from 'vue-router'
import auths from './routes/auths'
import dashboard from './routes/dashboard'
import others from './routes/others'
import store from '@/store/index'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',

  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      redirect: '/login',
      component: () => import('@/views/Index.vue'),
  
      meta: {
       
        layout: 'full',
       
       
      },
    },
   
   ...auths,
   ...others,
   ...dashboard,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})




router.beforeEach(function name(to, _, next) {

  if (to.meta.requiresAuth && !store.getters.isAuthenticated) {

    next({ name: 'login' })
      
  }else if(to.meta.requiresUnAuth && store.getters.isAuthenticated) {
    next({ name: 'dashboard' })
  }else if(store.getters.isAuthenticated  && store.getters.isVerifyEmail  && !to.meta.requiresEmail && !to.meta.requiresHome) {
    next({ name: 'verify-email' })
  }else {
      next()
  }
})


// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
